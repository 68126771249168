// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyCz9BRCOPEE5_CSgBkdEEhvnm1bM-Ras4Y",
    authDomain: "ao-docs.firebaseapp.com",
    databaseURL: "https://ao-docs.firebaseio.com",
    projectId: "ao-docs",
    storageBucket: "ao-docs.appspot.com",
    messagingSenderId: "993707107224",
    appId: "1:993707107224:web:745a8ad2d72935d21441ed",
    measurementId: "G-W218Q6KHY4"
  },
  clientId: '945784874224-lcru8ennqsdufi9i83qrvi6842idpj26.apps.googleusercontent.com',
  backend: {
    apiUrl: 'https://salesforce-connector.pre-prod.aodocs.app',
    salesforceLoginSaveCredentialsMethod: '/api/v1/salesforce/auth/saveCredentials',
    salesforceTechSignIn: '/api/v1/salesforce/auth/salesforceTechSignIn',
    salesforceUserSignIn: '/api/v1/salesforce/auth/salesforceUserSignIn',
    salesforceConfigEndpoint: '/api/v1/salesforce/auth/config',
    googleTechSignIn: '/api/v1/google/auth/googleTechSignIn',
    googleUserSignIn: '/api/v1/google/auth/googleUserSignIn',
    firebaseTechSignIn: '/api/v1/aodocs/auth/firebaseTechSignIn',
    userCheckEndpoint: '/api/v1/aodocs/auth/check',
    tenantIdEndpoint: '/api/v1/tenant/id',
    getObjectFields: '/api/v1/salesforce-frontend/api/getObjectFields',
    saveRule: '/api/v1/salesforce-rules',
    updateRule: '/api/v1/salesforce-rules/{ruleId}',
    listRules: '/api/v1/salesforce-rules',
    getRule: '/api/v1/salesforce-rules/{ruleId}',
    deleteRule: '/api/v1/salesforce-rules/{ruleId}',
    listAODocsMatchingTypes: '/api/v1/aodocs-actions/salesforce-aodocs-types',
    listAvailableClasses: '/api/v1/aodocs-actions/available-classes',
    canCreateAODocsRule: '/api/v1/aodocs-actions/can-create-rule',
    getAodocsRule: '/api/v1/aodocs-rules/{ruleId}',
    saveAodocsRule: '/api/v1/aodocs-rules',
    updateAodocsRule: '/api/v1/aodocs-rules/{ruleId}',
    deleteAodocsRule: '/api/v1/aodocs-rules/{ruleId}',
    getSalesforceObject: '/api/v1/aodocs-rules/salesforce-object/{classId}',
    listAppRules: '/api/v1/rules',
    aodocs: {
      getLibraries: '/api/v1/aodocs/libraries',
      getLibrary: '/api/v1/aodocs/libraries/{libraryId}',
      getClasses: '/api/v1/aodocs/libraries/{libraryId}/classes',
      getClassMetadata: '/api/v1/aodocs/libraries/{libraryId}/classes/{classId}/metadata',
      getClassWorkflowStates: '/api/v1/aodocs/libraries/{libraryId}/classes/{classId}/workflow-states',
    }
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
